<template>
  <div class="hzCompanies">
    <Head @closeMenu="closeOpenMenu" />
    <Menu ref="menu" />
    <div class="banner">
      <!-- <div class="forward"></div> -->
    </div>
    <div class="contents">
      <div class="contentCenter">
        <h3 class="title">西安有为信通软件科技有限公司</h3>
        <p class="lines">
          <span class="lineCenter"></span>
        </p>

        <div class="contextCenter">
          <div class="left"></div>
          <div class="right">
            <h2 class="titles">ABOUT</h2>
            <p class="textContent">
              西安有为信通软件科技有限公司集ICT产品增值分销与专业服务为一体的企业，客户涉及政府、金融、互联网、教育、制造、能源、交通等多个领域。面向未来，公司将秉承“真诚、奋斗、专业、创新、协作、奉献”的核心价值观，为厂商和合作伙伴提供创新、专业的“一站式”服务，成为中国立足服务于传统企业数字化转型的、引领行业发展的企业ICT资源整合服务提供商。
            </p>
             <a href="http://www.sxuweic.com/" target="_blank">
               <div class="EnName"></div></a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
export default {
  components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
  methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
};
</script>

<style scoped>
/* banner图开始 */
.banner {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/ItsCompanies/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.banner .forward {
  width: 164px;
  height: 44px;
  background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 58px;
  bottom: 26px;
}
/* banner图结束 */

/* 主体内容开始 */
.contents {
  width: 100%;
  height: 800px;
  background-image: url("../../assets/image/ItsCompanies/xaBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.contents .contentCenter {
  padding-top: 78px;
  color: #fff;
  max-width: 1466px;
  margin: 0 auto;
}
.contents .contentCenter .title {
  font-size: 40px;
  font-family: IOS9W4;
  font-weight: bold;
  color: #17171c;
}
.contents .contentCenter .lines {
  margin-bottom: 36px;
}
.contents .contentCenter .lines .lineCenter {
  width: 70px;
  height: 4px;
  background: #3c4047;
  border-radius: 2px;
  display: inline-block;
}
.contents .contentCenter .contextCenter {
  color: #333333;
  display: flex;
  padding-top: 37px;
  justify-content: space-between;
}
.contents .contentCenter .contextCenter .left {
  width: 661px;
  text-align: left;
  display: inline-block;
  background-image: url("../../assets/image/ItsCompanies/xaContent.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  overflow: hidden;
  height: 456px;
}
.contents .contentCenter .contextCenter .right {
  width: 850px;
  display: inline-block;
  text-align: left;
  padding-left: 36px;
}
.contents .contentCenter .contextCenter .right .titles {
  font-size: 50px;
  font-family: IOS9W4;
  font-weight: 400;
  color: #999999;
}
.contents .contentCenter .contextCenter .right .textContent {
  padding-top: 27px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 52px;
  width: 762px;
  height: 223px;
}
.contents .contentCenter .contextCenter .right .EnName {
  width: 766px;
  height: 58px;
  background-image: url("../../assets/image/ItsCompanies/xaEnName.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* margin: 0 auto; */
  margin-top: 90px;
   cursor: pointer;
}

/* hover 事件 */
.contents .contentCenter .contextCenter .left:hover {
  background-size: 105% 105%;
  transition: 0.5s;
}
</style>